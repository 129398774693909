import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Tile from "../../components/tile"

const DiagnosticGuidelines = () => (
	<Layout>
		<SEO title="Lupus diagnostic guidelines" />
		<h1>Lupus diagnostic guidelines</h1>
		<h2>Lupus is under diagnosed and often misdiagnosed</h2>
		<p>
			Lupus is difficult to diagnose. No single test can definitively determine
			whether a person has lupus — but clinical assessment along with several
			laboratory tests of blood and urine can help make a diagnosis.
		</p>
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
			<Tile
				text="Signs and symptoms"
				path="/lupus-diagnostic-guidelines/signs-and-symptoms"
			/>
			<Tile
				text="Lab tests"
				path="/lupus-lab-tests-diagnosing-and-monitoring"
			/>
			<Tile
				text="Newest criteria for lupus"
				path="/lupus-diagnostic-guidelines/newest-criteria-for-lupus-diagnosis"
			/>
			<Tile
				text="Do I have lupus?"
				path="/lupus-diagnostic-guidelines/quiz-do-i-have-lupus"
			/>
		</div>
	</Layout>
)

export default DiagnosticGuidelines
